// extracted by mini-css-extract-plugin
export var profile = "profile-module--profile--A3-ER";
export var profile__segment = "profile-module--profile__segment--oBTBm";
export var profile__avatrCropper = "profile-module--profile__avatrCropper--Ag3qI";
export var profile__avatar = "profile-module--profile__avatar--gDVyE";
export var profile__uploadBtn = "profile-module--profile__uploadBtn--0zQED";
export var profile__row = "profile-module--profile__row--7tYvT";
export var profile__item = "profile-module--profile__item---js1P";
export var profile__input = "profile-module--profile__input--yykBL";
export var profile__textarea = "profile-module--profile__textarea--hDsPH";
export var profile__submit = "profile-module--profile__submit--YSZBJ";
export var profile__error = "profile-module--profile__error--Lo9wc";
export var header = "profile-module--header--9xh-C";